/* stylelint-disable selector-class-pattern,unit-disallowed-list,declaration-no-important */
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.ConfidenceMessaging {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;

  &.debug {
    box-shadow: inset 0 0 0 1px blue;

    .item {
      box-shadow:
        inset 0 0 0 1px red,
        inset -48px 0 0 -24px rgba(255, 0, 0, 0.4);
    }
  }

  .item {
    display: flex;
    max-width: 33.3%;
    padding-right: tokens.bpk-spacing-lg();
    align-items: flex-start;
    flex: 1 1 33.33%;
    flex-basis: auto;
    color: tokens.$bpk-text-primary-day;
    gap: tokens.$bpk-one-pixel-rem * 12;

    @include utils.bpk-rtl {
      padding-right: 0;
      padding-left: tokens.bpk-spacing-lg();
    }
  }

  .icon {
    display: flex;
    justify-content: flex-start;
  }

  .description {
    align-items: center;
    font-size: tokens.$bpk-font-size-sm;
    line-height: tokens.$bpk-line-height-sm;
  }

  // Or when vertical flag is set
  &.vertical {
    .item {
      flex-flow: column nowrap;
      gap: tokens.$bpk-one-pixel-rem * 8;
    }
  }

  // Under tablet breakpoint display items in vertical style
  // Order rule is disabled here because it matters that these are applied _after_ the .item without breakpoint
  // so that they take priority.
  // stylelint-disable-next-line order/order
  @include breakpoints.bpk-breakpoint-tablet {
    .item {
      padding-right: tokens.$bpk-one-pixel-rem * 24;
      flex-flow: column nowrap;
      gap: tokens.$bpk-one-pixel-rem * 12;
    }
  }

  // Under mobile breakpoint display items in a column
  // stylelint-disable-next-line order/order
  @include breakpoints.bpk-breakpoint-mobile {
    position: relative;
    padding: 0;
    flex-flow: column nowrap;
    gap: tokens.$bpk-one-pixel-rem * 24;

    // Position item icons and descriptions inline
    .item {
      // Apply !important to properties which overlap with the ".vertical .item" to ensure these take priority
      max-width: 100%;
      padding-right: 0;
      flex-flow: row nowrap !important;
      flex: 1 0;
      gap: tokens.$bpk-one-pixel-rem * 12 !important;
    }
  }
}
