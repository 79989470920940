@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.ConfidenceMessagingV2 {
  .container {
    display: flex;
    width: fit-content;
    column-gap: tokens.bpk-spacing-lg();
    flex-flow: row;
  }

  .item {
    display: flex;
    width: 316 * tokens.$bpk-one-pixel-rem;
    padding: tokens.bpk-spacing-lg();
    flex-flow: column;
    border-radius: tokens.$bpk-border-radius-md;

    @include breakpoints.bpk-breakpoint-above-tablet {
      width: 33.3%;
      flex-flow: row;
    }
  }

  .icon {
    display: flex;
    width: tokens.bpk-spacing-lg();
    justify-content: flex-start;
  }

  .description {
    padding-top: tokens.bpk-spacing-md();
    padding-left: 0;
    text-wrap: wrap;

    @include breakpoints.bpk-breakpoint-above-tablet {
      padding-top: 0;
      padding-left: tokens.bpk-spacing-base();
    }
  }
}
